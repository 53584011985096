<template>
  <!-- 绑定邮箱 -->
  <div
    id="house-login"
    ref="loginContainer"
    :class="['layui-fulid register-bg', sentOk ? 'findpassword' : '']"
  >
    <div v-if="!sentOk" class="layui-form">
      <p>请完成注册流程</p>

      <!--            <div class="layui-input-block login">
                      <h2><img src="@/assets/img/icon-user.png"/>用户名</h2>
                      <input
                              v-model="username"
                              class="layui-input"
                              lay-verify="required"
                              placeholder="请输入用户名"
                              required
                              type="text"
                              @blur="userInputBlur()"
                              @focus="userInputFocus()"
                      />
                      <div
                              v-show="userTipDisplay"
                              ref="userTip"
                              :class="usernameFlag?'userTip2':'userTip'"
                              style="position: absolute; display: flex; float: left"
                      >
                          <div class="dot"></div>
                          {{this.usernamePrompt}}
                      </div>
                  </div>-->
      <div class="layui-input-block login">
        <h2><img src="@/assets/img/icon-email.png" />注册邮箱</h2>
        <input
          v-model="email"
          class="layui-input"
          lay-verify="required"
          placeholder="请输入邮箱"
          required
          type="text"
          @blur="emailInputBlur()"
        />
        <div
          v-show="emailTipDisplay"
          ref="userTip"
          class="userTip3"
          style="position: absolute; display: flex; float: left"
        >
          <div class="dot"></div>
          该邮箱已存在
        </div>
      </div>
      <div class="layui-input-block login">
        <h2><img src="@/assets/img/icon-password.png" />登录密码</h2>
        <input
          v-model="password"
          class="password"
          lay-verify="required"
          placeholder="请设置登录密码"
          required
          type="password"
          v-on:input="inputFunc()"
        />
        <div
          v-show="passwordTipDisplay"
          ref="passwordTip"
          class="passwordTip"
          style="display: flex"
        >
          <div class="dot"></div>
          长度6-16位字符,可包含数字，字母(区分大小写)
        </div>
      </div>
      <div class="else" style="margin: 25px 0 10px 0">
        <div
          class="le"
          style="
            text-align: center;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
          "
        >
          <input
            v-model="agree"
            checked
            style="margin-right: 4px"
            type="checkbox"
          />
          我已阅读并同意
          <router-link :to="{ path: '/question', query: { type: 6 } }"
            >《用户协议》
          </router-link>
        </div>
      </div>
      <button
        class="layui-btn login"
        lay-filter="user-login"
        lay-submit
        @click="onRegisterByEmail"
      >
        注册
      </button>
      <div class="no-account">
        其他登录方式?
        <router-link to="/login">
          <a>邮箱/手机号登录</a>
        </router-link>
      </div>
    </div>
    <div v-if="sentOk" class="layui-form">
      <img class="icon" src="@/assets/img/sentok-icon.png" />
      <h4>邮件已发送</h4>
      <button
        class="layui-btn login"
        lay-filter="user-login"
        lay-submit
        @click="backMainpage"
      >
        返回首页
      </button>
    </div>
  </div>
</template>

<script>
import { bindingEmail, checkEmail, checkUsername } from "../../service/index";
import { getIn, validEmail, validPwd } from "../../utils/index";
import { Loading } from "element-ui";

export default {
  data() {
    return {
      email: "",
      username: "",
      password: "",
      userTipDisplay: false,
      userCheckDisplay: false,
      emailCheckDisplay: false,
      passwordTipDisplay: false,
      passwordCheckDisplay: false,
      emailTipDisplay: false,
      agree: false,
      sentOk: false,
      usernamePrompt: "用户名设置后不可修改",
      usernameFlag: false,
    };
  },
  created() {
    let that = this;
    document.onkeydown = function (e) {
      e = window.event || e;
      if (
        that.$route.path == "/registerform" &&
        (e.code == "Enter" || e.code == "enter")
      ) {
        //验证在注册界面和按得键是回车键enter
        that.onRegisterByEmail(); //注册函数
      }
    };
  },
  mounted() {},
  methods: {
    login() {
      window.localStorage.removeItem("CSON_PAGE_TOKEN");
      this.$router.push("/login");
    },
    userInputBlur() {
      if (this.username.length > 0) {
        if (!this.checkUserInput()) {
          this.userTipDisplay = false;
          return;
        }
        if (
          !/^[A-Za-z0-9-_\u4e00-\u9fa5]{3,12}$/.test(this.username) ||
          !isNaN(+this.username)
        ) {
          this.$message.closeAll();
          this.$message.error(
            "用户名仅支持中英文、数字和下划线，且不能为纯数字"
          );
          this.userTipDisplay = false;
          return;
        }

        checkUsername(this.username).then((res) => {
          console.log(res);
          this.usernameFlag = res.result;
          if (res.result) {
            this.usernamePrompt = "该用户名已存在";
          } else {
            this.usernamePrompt = "用户名设置后不可修改";
            this.userTipDisplay = false;
          }
        });
      } else {
        this.userTipDisplay = false;
        this.usernameFlag = false;
        this.usernamePrompt = "用户名设置后不可修改";
      }
    },
    userInputFocus() {
      this.userTipDisplay = true;
      this.userCheckDisplay = false;
    },
    emailInputBlur() {
      if (this.email.length > 0) {
        if (!validEmail(this.email)) {
          this.$message.closeAll();
          this.$message.error("请输入合法邮箱");
        } else {
          checkEmail(this.email).then((res) => {
            if (res.result) {
              this.emailTipDisplay = true;
            } else {
              this.emailTipDisplay = false;
            }
          });
        }
      } else {
        this.emailTipDisplay = false;
      }
    },
    passwordInputBlur() {
      if (!this.checkPasswordInput()) {
        this.$message.closeAll();
        this.$message.error("密码设置不符合规则");
      }
    },
    passwordInputFocus() {
      if (this.checkPasswordInput()) {
        this.passwordCheckDisplay = true;
        this.passwordTipDisplay = false;
      } else {
        this.passwordCheckDisplay = false;
        this.passwordTipDisplay = true;
      }
    },
    inputFunc() {
      if (this.checkPasswordInput()) {
        this.passwordCheckDisplay = true;
        this.passwordTipDisplay = false;
      } else {
        this.passwordCheckDisplay = false;
        this.passwordTipDisplay = true;
      }
    },
    checkUserInput() {
      if (this.username.length < 3) {
        this.$message.closeAll();
        this.$message.error("用户名不能小于3位字符");
        return false;
      }
      if (this.username.length > 12) {
        this.$message.closeAll();
        this.$message.error("用户名不能大于12位字符");
        return false;
      }
      return true;
    },
    checkPasswordInput() {
      if (
        this.password.length == 0 ||
        this.password.length < 6 ||
        this.password.length > 16 ||
        !validPwd(this.password) ||
        /^\d{1,}$/.test(this.password) ||
        /^[a-zA-Z]*$/.test(this.password)
      ) {
        return false;
      }

      return true;
    },
    async onRegisterByEmail() {
      // 用户名仅支持中英文、数字和下划线，且不能为纯数字
      // if (!this.checkUserInput()) return;
      // if (
      //     !/^[A-Za-z0-9-_\u4e00-\u9fa5]{3,12}$/.test(this.username) ||
      //     !isNaN(+this.username)
      // ) {
      //     this.$message.closeAll();
      //     this.$message.error("用户名仅支持中英文、数字和下划线，且不能为纯数字");
      //     return;
      // }
      if (!validEmail(this.email)) {
        this.$message.closeAll();
        this.$message.error("请输入合法邮箱");
        return;
      }
      // 密码长度6-16位字符,可包含数字，字母(区分大小写)
      if (this.password.length == 0) {
        this.$message.closeAll();
        this.$message.error("请设置登录密码");
        return;
      }
      if (
        !this.password ||
        this.password.length < 6 ||
        !validPwd(this.password) ||
        /^\d{1,}$/.test(this.password) ||
        /^[a-zA-Z]*$/.test(this.password)
      ) {
        this.$message.closeAll();
        this.$message.error(
          "密码长度为6-16字符，必须包含数字，字母（区分大小写）"
        );
        return;
      }
      if (!this.agree) {
        this.$message.closeAll();
        this.$message.error("请选中用户协议后进行注册");
        return;
      }

      let captchaId = "2060393098";
      const captcha = new TencentCaptcha(captchaId, async (res) => {
        if (res.ret === 0) {
          var invitationEmailCode = this.$route.query.invitationEmailCode
            ? this.$route.query.invitationEmailCode
            : "";
          const result = await bindingEmail({
            userId: this.$route.query.userId,
            email: this.email,
            password: this.password,
            invitationEmailCode: invitationEmailCode,
          });
          if (result && result.code === 200) {
            // window.localStorage.setItem("CSON_PAGE_TOKEN", result.result);
            // this.$store.dispatch("queryUserInfo");
            this.sentOk = true;
            // window.location.href = "/#/";
            window.localStorage.removeItem("CSON_PAGE_TOKEN");
          } else {
            this.$message.closeAll();
            this.$message.error(
              getIn(result, ["message"]) || "系统繁忙，请稍后再试"
            );
          }
          console.log("result:", result);
        }
      });
      captcha.show();
    },
    findpassword() {
      this.$router.push("/findpassword");
    },
    goregister() {
      this.$router.push("/register");
    },
    backMainpage() {
      window.location.href = "/";
    },
  },
};
</script>
<style scoped>
.dot {
  background-color: white;
  height: 4px;
  width: 4px;
  border-radius: 2px;
  margin-right: 3px;
  margin-top: 8px;
}

.userTip {
  right: -50%;
  top: 50%;
  transform: translateY(-50%);
}

.userTip2 {
  right: -40%;
  top: 50%;
  transform: translateY(-50%);
}

.userTip3 {
  right: -36%;
  top: 50%;
  transform: translateY(-50%);
}

.passwordTip {
  position: absolute;
  right: -92%;
  top: 50%;
  transform: translateY(-50%);
}

.layui-input-block.login {
  position: relative;
}

.register-bg {
  background: url("../../assets/img/register-bg.jpg") no-repeat center center !important;
  background-size: 100% 100% !important;
}
</style>
